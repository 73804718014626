<script>
    import PageFooter from "./PageFooter.svelte";
    import PageHeader from "./PageHeader.svelte";

</script>

<section class="flex flex-col justify-between items-center min-h-full">
    <PageHeader />
    <main class="flex-1 w-full"><slot></slot></main>
    <PageFooter />
</section>

<style>
    section {
        background-image: linear-gradient(to bottom, #000, var(--surface-main));
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: var(--surface-main);
        color: var(--symbols-default);
    }
</style>