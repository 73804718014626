<script>
    import { page } from '$app/stores';
    import Button from './Button.svelte';
    import Modal from './Modal.svelte';
    import H2 from './H2.svelte';
    import { AuthApiError } from '@supabase/supabase-js';
    import { createEventDispatcher, onDestroy, tick } from 'svelte';
    import { fade } from 'svelte/transition';
    import Panel from './Panel.svelte';
    import Icon from './Icon.svelte';
    import { logEvent } from './utils/logs';
    import { browser } from '$app/environment';

    import * as Sentry from '@sentry/sveltekit';
    import Prose from './Prose.svelte';
    import { ERRORS } from './content/blocks/errors';

    let data = $page.data;

    let { supabase, session } = data;
    $: ({ supabase, session } = data);

    export let email = '';
    export let hidden = false;
    export let collapsible = true;
    export let button_props = {};

    let code = '';
    let message = '';

    let email_input;
    let code_input;

    export function show() {
        handle_modal_show();
    }

    export function hide() {
        handle_modal_close();
    }

    let showModal = false;

    /** @type {null|true|false} */
    let success = null;

    let loading = false;

    let is_code_sent = false;
    let time_since_sent = 0;
    let resend_time = 60;

    let dispatch = createEventDispatcher();

    /**
     * @type {string | number | NodeJS.Timeout | undefined}
     */
    let code_sent_interval;

    const start_code_sent_timer = () => {
        code_sent_interval = setInterval(() => {
            time_since_sent = time_since_sent + 1;
            if (time_since_sent >= resend_time) {
                clearInterval(code_sent_interval);
            }
        }, 1000);
    };

    onDestroy(() => {
        code_sent_interval && clearInterval(code_sent_interval);
    });

    const handle_send_code = async () => {
        is_code_sent = false;
        time_since_sent = 0;
        success = null;
        loading = true;
        code = '';
        message = '';

        try {
            const result = await fetch('/api/ml', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            }).then((res) => res.json());

            loading = false;

            if (result.success) {
                success = true;
                message = `We sent a code to <b>${email}</b>, please enter it below.`;
                is_code_sent = true;
                start_code_sent_timer();
                dispatch('code_sent');
                await tick();
                code_input.focus();
            } else {
                show_error({text: result.message || null});
                logEvent('auth:error:signInWithOtp', {
                    message: result.message
                });
                Sentry.captureException(
                    new Error('Get login code failed', { cause: result })
                );

            }
        } catch (error) {
            show_error({name: 'server_error'});

            Sentry.captureException(
                new Error('Get login code failed', { cause: error })
            );
            console.error('Get login code failed:', error);
        }
    };

    const handle_code_change = () => {
        if (code.length === 6) {
            handle_confirm_code();
        }
    };

    const handle_confirm_code = async () => {
        loading = true;
        message = '';
        const { data, error } = await supabase.auth.verifyOtp({
            email,
            token: code,
            type: 'magiclink'
        });

        if (error) {
            if (error?.status === 403) {
                show_error({name: 'otp_expired'});
            } else if (error instanceof AuthApiError && error.message) {
                show_error({text: error.message});
            } else {
                show_error();
            }

            loading = false;

            logEvent('auth:error:verifyOtp', { message: error.message });

            return;
        }

        dispatch('loged', { session: data.session });

        const ctm_email = localStorage.getItem('ctm_email');

        if (data.session.user.email !== ctm_email) {
            localStorage.setItem('ctm_email', data.session.user.email);
            localStorage.removeItem('ctm_id');
            localStorage.removeItem('add_id');
        }

        await tick();
        loading = false;
        showModal = false;
    };

    const handle_email_change = async () => {
        reset_state();
        await tick();
        email_input.focus();
    };

    const handleSignOut = async () => {
        await supabase.auth.signOut();
        location.reload();
    };

    const handle_modal_close = () => {
        if (!collapsible) return;
        showModal = false;
        reset_state();
    };

    const handle_modal_show = async () => {
        showModal = true;
        reset_state();

        email = localStorage.getItem('ctm_email') || '';

        // this is important because modal animation
        await new Promise((resolve) => setTimeout(resolve, 500));
        email_input.focus();
    };

    const reset_state = () => {
        code = '';
        message = '';
        success = null;
        is_code_sent = false;
        time_since_sent = 0;
        loading = false;
    };
    
    const show_error = ({text = null, name = 'generic'}) => {
        message = text || ERRORS.auth[name];
        success = false;
    };


</script>

{#if !hidden}
    {#if session}
        <Button
            outline
            on:click={handleSignOut}
            class="whitespace-nowrap"
            {...{ ...{ outline: true }, ...button_props }}
        >
            <Icon log_out class="max-sm:hidden" /> Log Out
        </Button>
    {:else}
        <Button
            class="whitespace-nowrap"
            {...{
                ...{ primary: true, outline: true },
                ...button_props
            }}
            on:click={handle_modal_show}
            loading={!browser}
        >
            <slot name="button">
                <Icon log_in class="max-sm:hidden" /> Log In
            </slot>
            <svelte:fragment slot="small"
                ><slot name="button_small" /></svelte:fragment
            >
        </Button>
    {/if}
{/if}

{#if showModal}
    <Modal on:close={handle_modal_close} sm {collapsible}>
        <H2 invert class="max-w-xs mx-auto mt-8"
            >Please log in with the email used for purchase</H2
        >
        {#if message}
            <Panel
                {success}
                error={!success}
                solid
                class="my-4 mx-auto max-w-xs"
            >
                {@html message}

                {#if success && is_code_sent}
                    <Button
                        nop
                        text
                        class="!align-baseline"
                        on:click={handle_email_change}>Change email</Button
                    >
                {/if}
            </Panel>
        {/if}

        {#if !is_code_sent}
            <form
                class="flex flex-col gap-3 max-w-xs mx-auto mt-4"
                on:submit|preventDefault={handle_send_code}
            >
                <div class="flex flex-col gap-2">
                    <label for="email">Email</label>
                    <input
                        bind:this={email_input}
                        class="flex-1 !py-3"
                        id="email"
                        name="email"
                        type="email"
                        bind:value={email}
                        autocomplete="email"
                        required
                        title="Should be a valid email"
                    />
                </div>

                {#if !is_code_sent && success !== true}
                    <Button primary outline invert submit xl {loading} loading_spinner>Continue</Button
                    >
                {/if}
            </form>
        {:else}
            <form
                class="flex flex-col gap-3 mt-3 max-w-xs mx-auto"
                on:submit|preventDefault={handle_confirm_code}
                in:fade
            >
                <div class="flex flex-col gap-2">
                    <label for="code">Code</label>
                    <input
                        bind:this={code_input}
                        class="flex-1 !py-3"
                        id="code"
                        name="code"
                        type="text"
                        inputmode="numeric"
                        pattern="\d{'{'}6{'}'}"
                        bind:value={code}
                        autocomplete="one-time-code"
                        required
                        title="Should be a 6 digit code"
                        on:input={handle_code_change}
                    />
                </div>

                <Button primary outline invert submit xl {loading} loading_spinner>Log in</Button>

                {#if time_since_sent >= resend_time}
                    <Button sm on:click={handle_send_code}>Resend code</Button>
                {:else}
                    <p
                        class="text-center text-sm tabular-nums text-symbols-inverted-muted"
                    >
                        Resend code in {resend_time - time_since_sent} seconds
                    </p>
                {/if}
            </form>
        {/if}

        <div class="border-t border-border-invert-muted mt-8 pt-4">
            <Prose invert xs class="max-w-xs mx-auto">
                <p>
                    By logging in, you agree to the <a
                        href="/terms"
                        target="_blank"
                        class="link">Terms of Service</a
                    >
                    and
                    <a href="/privacy" target="_blank" class="link"
                        >Privacy Policy</a
                    >.
                </p>
                <p>
                    If you are 13 or younger, you must have the permission of a
                    parent or guardian.
                </p>
            </Prose>
        </div>
    </Modal>
{/if}
