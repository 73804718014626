<script>
	import { fade } from "svelte/transition";

    export let success = false;
    export let error = false;

    export let solid = false;

    let className = '';
    export { className as class }
</script>

<div in:fade class="p-4 rounded-2xl  border
    {
        success && solid ? 'border-green-300 bg-green-50 text-green-900 dark:bg-green-950 dark:text-green-100 dark:border-green-900'
        : success ? 'border-green-500/50 bg-green-50/5' 
        : error && solid ? 'border-red-300 bg-red-50 text-red-900 dark:bg-red-950 dark:text-red-100 dark:border-red-900'
        : error ? 'border-red-500/50 bg-red-50/5 text-red-50'
        : solid ? 'border-gray-400 bg-gray-50 text-gray-700'
        : 'bg-gray-50/5 text-gray-50 border-gray-500/50'
    }
    {className}
"><slot/></div>