<script>
    import { page } from "$app/stores";
    import Social from "./Social.svelte";

    let footer_html = $page.data.footer_links;
    
</script>

<footer class="py-4 mt-8 bg-black/30 w-full">
    <div
        class="flex flex-col md:flex-row gap-4 items-start justify-between container px-4 lg:px-8 mx-auto"
    >
        <div class="flex flex-col gap-2">
            <a class="link" href="/courses">Courses</a>
            <a class="link" href="/worksheets">Worksheets</a>
            <a class="link" href="/bundles">Bundles</a>
            <a class="link" href="/puzzles">Puzzles</a>
            <a class="link" href="/privacy">Privacy</a>
            <a class="link" href="/terms">Terms</a>
        </div>

        <div class="flex flex-col gap-2">
            {@html footer_html}
        </div>
            
        <div class="flex flex-col gap-2 justify-between self-stretch items-start">
            <div>
                <h4 class="font-bold mb-2">Follow me on</h4>
                <Social sm/>
                <h4 class="font-bold mb-1 mt-6">Message me at</h4> 
                <a href="mailto:stephanie@critikid.com" class="link">stephanie@critikid.com</a>
            </div>
            <span class="self-end opacity-80">&copy; Critikid.com 2024</span>
        </div>
    </div>
</footer>


<style>
    @media (any-hover: hover) {
        footer :global(a:hover) {
            text-decoration: underline;
        }
    }
</style>