<script>
    // import { browser } from '$app/environment';
    import { page } from '$app/stores';

    import Auth from './Auth.svelte';
    // import Button from './Button.svelte';
    import DiscountBanner from './DiscountBanner.svelte';
    // import Icon from './Icon.svelte';

    import Logo from './Logo.svelte';

    import nav_yml from './content/blocks/topnav.yml?raw';
    import yaml from 'js-yaml';

    let nav_items = yaml.load(nav_yml);

    const handle_logged = () => {
        let params = new URLSearchParams(location.search);

        if (params.get('redirectTo')) {
            location.href = params.get('redirectTo');
        } else {
            location.reload();
        }
    };

    let path = $page.url.pathname;

</script>

<header
    class="container flex justify-between flex-wrap items-center px-4 sm:px-8 py-4 md:flex-nowrap gap-4"
>
    <a href="/" class="flex-shrink-0">
        <Logo class="w-44 xs:w-56 md:w-52 lg:w-64" />
    </a>

    <nav
        class="flex gap-0 items-center justify-center flex-1 max-md:order-1 md:justify-end
            xs:border-x border-b border-border-highlight
            xs:rounded-full xs:px-4 py-2 md:flex-grow-0 md:justify-self-end
            max-xs:-mx-4
            lg:mr-28
        "
    >
        {#each nav_items as item, i (item)}
            <a
                href={item.path}
                class="px-2 py-1 rounded-full {path === item.path ? 'bg-surface-highlight'
                    : path.startsWith(item.path) ? 'bg-surface-highlight link'
                    : 'link'}"
            >
                {item.title}
            </a>
        {/each}
    </nav>

    <Auth
        on:loged={handle_logged}
        button_props={{
            pill: true,
            class: 'md:border-t-0'
        }}
    />
</header>
