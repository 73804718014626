import { isProd, site_origin } from '$lib/stores/globals';

/**
 * Transforms an image by constructing a URL for Netlify's Image Transformation service.
 *
 * @param {string} source - a URL or a path to image.
 * @param {ImageTransformOptions} options
 * @returns {string} The URL of the transformed image.
 */
export const img_cdn = (source = '', options = {}) => {
    if (!isProd) return `${source.startsWith('/') ? '' : '/'}${source}`;
    const base_url = `${site_origin}/.netlify/images/`;

    let u = new URL(base_url);
    u.searchParams.set('url', source);
    Object.entries(options).forEach(([key, value]) => {
        u.searchParams.set(key, value);
    });

    return u.toString();
};




/**
 * @param {string} source - a URL or a path to image.
 * @param {ImageTransformOptions} options
 * @returns {string} The URL of the transformed image.
 */
export const img_cdn_srcset = (source = '', options = {}) => {
    // Never do this shit because you will spend hours looking for bugs in production that don't exist in dev
    // if (!isProd) return '';

    if (!options.w || !options.h) return '';

    let opts2x = { ...options};
    let opts3x = { ...options};

    if (options.w) {
        opts2x.w = options.w * 2;
        opts3x.w = options.w * 3;
    }

    if (options.h) {
        opts2x.h = options.h * 2;
        opts3x.h = options.h * 3;
    }

    let srcset = [
        `${img_cdn(source, options)} 1x`,
        `${img_cdn(source, opts2x)} 2x`,
        `${img_cdn(source, opts3x)} 3x`
    ];

    return srcset.join(', ');
}

/**
 * @param {string} img_url
 * @param {string} text 
 * @param {object} options
 * @param {string=} options.font
 * @param {string=} options.color
 * @param {number=} options.top
 * @param {number=} options.left
 * 
 * 
// */
// export const img_wtmk = async (img_url, text, options) => {

//     const img = new Image();
//     img.src = img_url;





//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");
    
//     const canvasWidth = original_image.width;
//     const canvasHeight = original_image.height;
    
//     canvas.width = canvasWidth;
//     canvas.height = canvasHeight;
    
//     // initializing the canvas with the original image
//     context.drawImage(original_image, 0, 0, canvasWidth, canvasHeight);
    
//     // adding a blue watermark text in the bottom right corner
//     context.fillStyle = "blue";
//     context.textBaseline = "middle";
//     context.font = "bold 25px serif";
//     context.fillText(watermarkText, canvasWidth - 100, canvasHeight - 20);
    
//     return canvas.toDataURL();

// }
