<script>
    import { img_cdn } from "./utils/image";

    export let sm = false;
    let className = "";
    export { className as class };
</script>

<div
    class="flex nowrap mx-auto gap-4 items-center justify-center max-w-md {className}"
>
    <a
        href="https://www.facebook.com/profile.php?id=61551653827260"
        class="opacity-90 hover:opacity-100 transition-opacity"
        target="_blank"
        ><img
            src={img_cdn("/logo-fb.svg")}
            alt="Facebook"
            class="aspect-square {sm ? 'w-6' : 'w-8'}"
            loading="lazy"
        /></a
    >
    <a
        href="https://www.instagram.com/critikid"
        class="opacity-90 hover:opacity-100 transition-opacity"
        target="_blank"
        ><img
            src={img_cdn("/logo-instagram.svg")}
            alt="Instagram"
            class="aspect-square {sm ? 'w-6' : 'w-8'}"
            loading="lazy"
        /></a
    >
    <a
        href="https://twitter.com/critikids"
        target="_blank"
        class="opacity-90 hover:opacity-100 transition-opacity"
        ><img
            src={img_cdn("/logo-x.svg")}
            alt="X"
            class="aspect-square {sm ? 'w-6' : 'w-8'}"
            loading="lazy"
        /></a
    >

    <a
        href="https://www.tiktok.com/@critikid"
        target="_blank"
        class="opacity-90 hover:opacity-100 transition-opacity"
        ><img
            src={img_cdn("/logo-tiktok.svg")}
            alt="TikTok"
            class="aspect-square {sm ? 'w-6' : 'w-8'}"
            loading="lazy"
        /></a
    >

    <a
        href="https://www.linkedin.com/in/stephanie-simoes-b4a159157/"
        target="_blank"
        class="opacity-90 hover:opacity-100 transition-opacity"
        ><img
            src={img_cdn("/logo-ln.png")}
            alt="LinkedIn"
            class="aspect-square {sm ? 'w-6' : 'w-8'}"
            loading="lazy"
        /></a
    >
</div>
