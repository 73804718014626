<script>
    let className = '';
    export { className as class };
    export let lg = false;
    export let invert = false;
</script>

<h2
    class="font-extrabold
    {lg ? 'text-2xl md:text-4xl' : 'text-2xl md:text-3xl'}
    {invert
        ? 'text-symbols-invert-highlight'
        : 'text-symbols-highlight'}   {className}"
>
    <slot />
</h2>
